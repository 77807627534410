import React from "react";
import { graphql } from "gatsby";
import Layout from "@components/layout";
import { PageMeta } from "@components/pageMeta";
import IntroBlock from "@components/other/introBlock";
import { useCaseStudyQuery } from "@querys/useCaseStudyQuery";
import { SanityImage } from "@components/sanity/sanityImage";
import ArrowButton from "@components/UI/arrowButton";
import { Link } from "gatsby";

const CaseStudies = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data?.page;
  const { seo, title, subtitle } = page || {};
  const { caseStudies } = useCaseStudyQuery();

  return (
    <Layout reversedHeader={true}>
      <PageMeta {...seo} />
      <IntroBlock title={title} subtitle={subtitle} />
      <div className="px-gutter pt-space-m grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-[45px] gap-y-[74px]">
        {caseStudies?.map((study) => (
          <Link to={`/case-studies/${study?.slug?.current}`}>
            {study?.solutionRef?.title && (
              <div className="blockH4">
                <ArrowButton linkText={study?.solutionRef?.title} />
              </div>
            )}
            <div className="relative aspect-1 rounded-[20px] overflow-hidden my-10">
              <SanityImage
                image={study?.featuredImage}
                className=" inset-0 w-full h-full object-cover"
              />
            </div>

            <h3 className="blockH4">{study?.title}</h3>
            <p className="blockH5 line-clamp-1 pt-5 text-grey">
              {study?.subtitle}
            </p>
          </Link>
        ))}
      </div>
    </Layout>
  );
};

export default CaseStudies;

export const pageQuery = graphql`
  query sanityCaseStudiesHomeQuery {
    page: sanityCaseStudiesHome {
      title
      subtitle
      seo {
        ...SEO
      }
    }
  }
`;
